@keyframes animatedBorder {
    0% {
        border: 7px solid var(--red-50);
    }
    50% {
        border: 5px solid var(--red-50);
    }
    100% {
        border: 3px solid var(--red-50);
    }
}
.darkBG {
    z-index: 99;
    position: absolute;
    background: rgba(12, 12, 12, 0.5);
    min-height: 100%;
    max-height: 200%;
    width: 100%;
    animation: fadeIn 0.3s forwards;
}
.lightBG {
    background: transparent !important;
    box-shadow: 10px 10px 20px 0px #0000001F;
    animation: fadeIn 0.3s forwards;
}


.lightBG .model-content-alert {
    margin: 4% 35% !important;
}
.lightBG .modal-header-alert {
    border-radius:10px !important;
    background: var(--primary-100) !important;
}
.lightBG .modal-header-alert-error {
    border-radius:10px !important;
    background: var(--red) !important;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-content {
    position: relative;
    display: flex;
    margin: 8% 20%;
    padding: 0;
    flex-direction: column;
    pointer-events: auto;
    background-clip: padding-box;
    /*border: 1px solid rgba(0, 0, 0, 0.2);*/
    outline: 0;
    align-items: center;
    gap: 16px;

    width: 950px;

    background: var(--white);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    border: none;
    max-height: 500px;
    animation: slideIn 0.3s forwards;
}
.lightBG .modal-content {
    width: 650px!important;
}
.lightBG .modal-header {
    padding: 16px 20px !important;
    /*opacity: 80%*/
}
.form-modal {
    margin: 2% 20% !important;
    max-height: 98vh !important;
}

.modal-header {
    background: var(--primary-100) !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    width: 100%;
    padding: 20px;
}
.modal-heading-title {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white)
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    padding: 15px 20px;
    /*padding: 0.5rem;*/
}
.modal-body-update {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    padding: 15px 20px;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    /*padding: 0.5rem;*/
}
.modal-footer {
    padding: 0 20px 15px 20px;
    width: 100%
}
.body-image-outer {
    background: var(--red);
    border: 7px solid var(--red-50);
    border-radius: 50px;
    height: 52px;
    width: 52px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    align-items: center;
    animation: animatedBorder 1s infinite;
}
.p-question {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.p-answer {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-75);
}
.body-text {
    margin: 20px 0 0 0;
}
.body-text-form {
    margin: -10px 0 0 0;
}
.body-text-form-read {
    padding: 0 20px;
    width: 100%;
}
.body-text-form-read .wrapper-editor {
    border: none !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
}
.modal-button {
    width: 150px
}
.padding-bottom-modal {
    padding-bottom: 35px !important;
    margin-bottom: 15px !important;
    border-bottom: 2px solid var(--grey-white)
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-50px);
    }
    to {
        transform: translateY(0);
    }
}
#header {
    background-color: var(--white) !important;
    border-right: 1px solid var(--grey-white);
}
#header .pro-sidebar {
    height: 88vh;
    /*overflow-y: scroll;*/
}
.pro-sidebar-content {
    height: 70vh;
    overflow-y: scroll;
}
#header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
}
#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}
#header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}
#header .pro-sidebar-inner {
    background-color: var(--white);
    /*box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);*/
}
#header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    font-family: var(--font-family-regular);
    font-size: 16px!important;
    font-style: normal;
    line-height: normal;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    font-family: "work-sans-regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;

    /* identical to box height */
    /*color: var(--main-black-color);*/
    /* Inside auto layout */
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
    margin: 0 20px;
    font-family: var(--font-family-regular);
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
    color: var(--black-100) !important;
    font-family: var(--font-family-bold) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: var(--light-blue);
    border-left: 4px solid var(--red);
}
#header .logo {
    padding: 20px;
}

@media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }
}
.logo-image {
    padding: 20px 0 0 0;
    cursor: pointer;
    width: 100%;
}
.logo-image-resize {
    width: 75%;
}
.pro-sidebar-header {
    border: none !important;
}
.pro-sidebar-footer {
    border: none !important;
}
.pro-menu-item {
    margin: 10px 0;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-family: var(--font-family-regular);
}
.pro-sidebar .pro-menu .pro-menu-item {
    color: var(--black-75) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: var(--primary-75);
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: var(--primary-100);
}
.pro-arrow {
    display: none!important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: var(--white) !important;
    border-radius: 10px;
    padding: 5px 0 5px 0;
    width: 85%;
    margin: -15px 0 0 10px;
}
.sub-menu {
    font-family: work-sans-bold,sans-serif !important;
    font-style: normal;
    font-weight: 900!important;
    font-size: 14px!important;
    line-height: 40px;

    color: var(--black-100);
}
.menu-font {
    /*font-family: var(--font-family-regular);*/
    margin: 0 0 0 15px;
}
.menu-row {
    align-items: center;
    background-color: transparent
}
.menu-font-logout {
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* identical to box height */

    color: #FF4E4E!important;
    margin-top: 40%;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 20px 10px 20px 40px!important;
}
.header-menu {
    margin-bottom: 30px;
}
ul {
    list-style-type: none;
}
.sidebar-footer {
    position: absolute;
    font-family: var(--font-family-regular);
    color: var(--black-100) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 30px 10px 20px 40px!important;
    background-color: var(--white);
    height: 12vh;
    width: 20%;
    border-top: 1px solid var(--grey-white);
    border-right: 1px solid var(--grey-white);
}
.menu-font-footer {
    /*font-family: var(--font-family-regular);*/
    padding: 0 0 0 10px;
    cursor: pointer;
}
.pro-item-content > .row > * {
    padding-left: 0;
}
.pro-sidebar .pro-menu .active > .pro-inner-item {
    padding: 20px 10px 20px 35px !important;
}

@media (min-width: 1366px) {
    /*#header .pro-sidebar {*/
    /*    height: 90vh !important;*/
    /*    overflow-y: scroll;*/
    /*}*/
    /*.sidebar-footer {*/
    /*    height: 10vh !important;*/
    /*}*/
    /*.pro-sidebar-content {*/
    /*    height: 85vh;*/
    /*    overflow-y: hidden !important;*/
    /*}*/
}
@media (min-width: 1900px) {
    #header .pro-sidebar {
        height: 92vh !important;
        /*overflow-y: scroll;*/
    }
    .sidebar-footer {
        height: 8vh !important;
    }
    .pro-sidebar-content {
        height: 78vh;
        overflow-y: scroll;
    }
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}
.toggle-switch input[type="checkbox"] {
    display: none;
}
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: var(--black-25);
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 5px;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: var(--primary-100);
}
.toggle-switch input[type="checkbox"]:checked + .switch {
    border: 1px solid var(--primary-100) !important;
    background-color: var(--white);
}
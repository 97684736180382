@font-face {
    font-family: "montserrat-regular";
    src: url("assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "montserrat-medium";
    src: url("assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: "montserrat-black";
    src: url("assets/fonts/Montserrat-Black.ttf");
}

@font-face {
    font-family: "montserrat-bold";
    src: url("assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: "montserrat-semi-bold";
    src: url("assets/fonts/Montserrat-SemiBold.ttf");
}

:root {
    --dark: #1E1E1E;
    --black-100: #333B44;
    --black-75: #666D73;
    --black-50: #979D9F;
    --black-25: #CBCDCE;
    --white: rgba(255, 255, 255, 1);
    --grey-white: #E9E9E9;
    --grey-white-1: #F2F2F2;
    --grey-white-2: #CAC7C6;
    --grey-white-3: #F0F1F4;
    --light-blue: #E4F4FB;
    --green: #029802;
    --red: #E0635D;
    --red-50: #ECAEAB;
    --red-10: rgba(224, 99, 93, 0.1);
    --red-error: #FF2626;
    --yellow: #FFA500;
    --primary-100: #4DA8B7;
    --primary-75: #56ACB8;
    --primary-50: #92D2DA;
    --primary-25: #C8E8EC;
    --blue-50: #E4F4FB;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-regular: "montserrat-regular";
    --font-family-medium: "montserrat-medium";
    --font-family-black: "montserrat-black";
    --font-family-bold: "montserrat-bold";
    --font-family-semi-bold: "montserrat-semi-bold";
}

.row,
html,
body {
    margin: 0 !important;
    padding: 0 !important;
    /*height: 100%;*/
    background-color: var(--light-blue);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 5px !important;
}

input:focus {
    outline: none;
}

input::placeholder {
    font-weight: 400;
}

.button-solid {
    display: flex;
    padding: 14px 24px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border: none;
    border-radius: 8px;
    background: var(--primary-100);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--grey-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--grey-white-2);
    height: 10px

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--grey-white-2);
}


input:disabled {
    opacity: 50%
}

textarea {
    background: transparent;
    border-style: none;
    border-color: Transparent;
    overflow: auto;
    outline: none;
    resize: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.button-cursor {
    cursor: pointer;
}

#main {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof {
    display: table-cell;
    vertical-align: middle;
}

.fof h1 {
    font-family: var(--font-family-semi-bold);
    font-color: var(--black-75);
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }
    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}

input[type="search"] {
    width: 100%;
    margin: 0;

    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 500;
    border: 1px solid var(--black-25);
    border-radius: 10px;
    /*background: transparent  url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z' fill='%234DA8B7'/%3E%3Cpath d='M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z' fill='%234DA8B7'/%3E%3C/svg%3E") no-repeat center;*/
}
input.nosubmit {
    padding: 12px 4px 12px 40px;
    background: transparent  url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z' fill='%234DA8B7'/%3E%3Cpath d='M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z' fill='%234DA8B7'/%3E%3C/svg%3E") no-repeat 10px center;
}

input[type="search"]::placeholder {
    font-family: var(--font-family-regular);
    color: var(--black-75);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.padding-right-1 {
    padding-right: 80px !important;
}
.padding-right-2 {
    padding-right: 120px !important;
}
/*@media (min-width: 1400px) {*/
/*    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {*/
/*        max-width: 100%!important;*/
/*        padding:unset!important;*/
/*        margin:unset!important;*/
/*    }*/
/*}*/
/*.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {*/
/*    max-width: 100%!important;*/
/*    padding:unset!important;*/
/*    margin:unset!important;*/
/*}*/


/*@charset "UTF-8";*/
/*:root {*/
/*    --dt-row-selected: 13, 110, 253;*/
/*    --dt-row-selected-text: 255, 255, 255;*/
/*    --dt-row-selected-link: 9, 10, 11;*/
/*}*/
/*table td.dt-control {*/
/*    text-align: center;*/
/*    cursor: pointer;*/
/*}*/
/*table td.dt-control:before {*/
/*    height: 1em;*/
/*    width: 1em;*/
/*    margin-top: -9px;*/
/*    display: inline-block;*/
/*    color: white;*/
/*    border: 0.15em solid white;*/
/*    border-radius: 1em;*/
/*    box-shadow: 0 0 0.2em #444;*/
/*    box-sizing: content-box;*/
/*    text-align: center;*/
/*    text-indent: 0 !important;*/
/*    font-family: var(--font-family-regular);*/
/*    line-height: 1em;*/
/*    content: "+";*/
/*    background-color: #31b131;*/
/*}*/
/*table tr.dt-hasChild td.dt-control:before {*/
/*    content: "-";*/
/*    background-color: #d33333;*/
/*}*/
.table-react th {
    background-color: var(--light-blue)!important;
}
/*tr {*/
/*    border-bottom: transparent;*/
/*}*/

/*table thead > tr > th.sorting, table thead > tr > th.sorting_asc, table thead > tr > th.sorting_desc, table thead > tr > th.sorting_asc_disabled, table thead > tr > th.sorting_desc_disabled,*/
/*table thead > tr > td.sorting,*/
/*table thead > tr > td.sorting_asc,*/
/*table thead > tr > td.sorting_desc,*/
/*table thead > tr > td.sorting_asc_disabled,*/
/*table thead > tr > td.sorting_desc_disabled {*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    padding-right: 26px;*/
/*}*/
/*table thead > tr > th.sorting:before, table thead > tr > th.sorting:after, table thead > tr > th.sorting_asc:before, table thead > tr > th.sorting_asc:after, table thead > tr > th.sorting_desc:before, table thead > tr > th.sorting_desc:after, table thead > tr > th.sorting_asc_disabled:before, table thead > tr > th.sorting_asc_disabled:after, table thead > tr > th.sorting_desc_disabled:before, table thead > tr > th.sorting_desc_disabled:after,*/
/*table thead > tr > td.sorting:before,*/
/*table thead > tr > td.sorting:after,*/
/*table thead > tr > td.sorting_asc:before,*/
/*table thead > tr > td.sorting_asc:after,*/
/*table thead > tr > td.sorting_desc:before,*/
/*table thead > tr > td.sorting_desc:after,*/
/*table thead > tr > td.sorting_asc_disabled:before,*/
/*table thead > tr > td.sorting_asc_disabled:after,*/
/*table thead > tr > td.sorting_desc_disabled:before,*/
/*table thead > tr > td.sorting_desc_disabled:after {*/
/*    position: absolute;*/
/*    display: block;*/
/*    opacity: 0.125;*/
/*    right: 10px;*/
/*    line-height: 9px;*/
/*    font-size: 0.8em;*/
/*}*/
/*table thead > tr > th.sorting:before, table thead > tr > th.sorting_asc:before, table thead > tr > th.sorting_desc:before, table thead > tr > th.sorting_asc_disabled:before, table thead > tr > th.sorting_desc_disabled:before,*/
/*table thead > tr > td.sorting:before,*/
/*table thead > tr > td.sorting_asc:before,*/
/*table thead > tr > td.sorting_desc:before,*/
/*table thead > tr > td.sorting_asc_disabled:before,*/
/*table thead > tr > td.sorting_desc_disabled:before {*/
/*    bottom: 50%;*/

/*    !*content: "▲";*!*/
/*    !*content: "▲"/"";*!*/
/*}*/
/*table thead > tr > th.sorting:after, table thead > tr > th.sorting_asc:after, table thead > tr > th.sorting_desc:after, table thead > tr > th.sorting_asc_disabled:after, table thead > tr > th.sorting_desc_disabled:after,*/
/*table thead > tr > td.sorting:after,*/
/*table thead > tr > td.sorting_asc:after,*/
/*table thead > tr > td.sorting_desc:after,*/
/*table thead > tr > td.sorting_asc_disabled:after,*/
/*table thead > tr > td.sorting_desc_disabled:after {*/
/*    top: 50%;*/

/*    !*content: "▼";*!*/
/*    !*content: "▼"/"";*!*/
/*}*/
/*table thead > tr > th.sorting_asc:before, table thead > tr > th.sorting_desc:after,*/
/*table thead > tr > td.sorting_asc:before,*/
/*table thead > tr > td.sorting_desc:after {*/
/*    opacity: 0.6;*/
/*}*/
/*table thead > tr > th.sorting_desc_disabled:after, table thead > tr > th.sorting_asc_disabled:before,*/
/*table thead > tr > td.sorting_desc_disabled:after,*/
/*table thead > tr > td.sorting_asc_disabled:before {*/
/*    display: none;*/
/*}*/
/*table thead > tr > th:active,*/
/*table thead > tr > td:active {*/
/*    outline: none;*/
/*}*/
/*table thead .sorting  {*/
/*    background: url('../../../assets/sort-icon/sort_asc.png') no-repeat left 15px top 12px;*/
/*}*/
/*table thead .sorting_asc {*/
/*    background: url('../../../assets/sort-icon/sort_asc.png') no-repeat left 15px top 12px !important*/
/*}*/

/*table thead .sorting_desc {*/
/*    background: url('../../../assets/sort-icon/sort_desc.png') no-repeat left 15px top 12px !important*/
/*}*/

/*table thead .sorting_asc_disabled {*/
/*    background: url('../../../assets/sort-icon/sort_asc.png') no-repeat left 15px top 12px*/
/*}*/

/*table thead .sorting_desc_disabled {*/
/*    background: url('../../../assets/sort-icon/sort_desc.png') no-repeat left 15px top 12px*/
/*}*/
/*div.dataTables_scrollBody > table > thead > tr > th:before, div.dataTables_scrollBody > table > thead > tr > th:after,*/
/*div.dataTables_scrollBody > table > thead > tr > td:before,*/
/*div.dataTables_scrollBody > table > thead > tr > td:after {*/
/*    display: none;*/
/*}*/

/*div.dataTables_processing {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    width: 200px;*/
/*    margin-left: -100px;*/
/*    margin-top: -26px;*/
/*    text-align: center;*/
/*    padding: 2px;*/
/*}*/
/*div.dataTables_processing > div:last-child {*/
/*    position: relative;*/
/*    width: 80px;*/
/*    height: 15px;*/
/*    margin: 1em auto;*/
/*}*/
/*div.dataTables_processing > div:last-child > div {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    width: 13px;*/
/*    height: 13px;*/
/*    border-radius: 50%;*/
/*    background: rgb(13, 110, 253);*/
/*    background: rgb(var(--dt-row-selected));*/
/*    animation-timing-function: cubic-bezier(0, 1, 1, 0);*/
/*}*/
/*div.dataTables_processing > div:last-child > div:nth-child(1) {*/
/*    left: 8px;*/
/*    animation: datatables-loader-1 0.6s infinite;*/
/*}*/
/*div.dataTables_processing > div:last-child > div:nth-child(2) {*/
/*    left: 8px;*/
/*    animation: datatables-loader-2 0.6s infinite;*/
/*}*/
/*div.dataTables_processing > div:last-child > div:nth-child(3) {*/
/*    left: 32px;*/
/*    animation: datatables-loader-2 0.6s infinite;*/
/*}*/
/*div.dataTables_processing > div:last-child > div:nth-child(4) {*/
/*    left: 56px;*/
/*    animation: datatables-loader-3 0.6s infinite;*/
/*}*/

/*@keyframes datatables-loader-1 {*/
/*    0% {*/
/*        transform: scale(0);*/
/*    }*/
/*    100% {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/
/*@keyframes datatables-loader-3 {*/
/*    0% {*/
/*        transform: scale(1);*/
/*    }*/
/*    100% {*/
/*        transform: scale(0);*/
/*    }*/
/*}*/
/*@keyframes datatables-loader-2 {*/
/*    0% {*/
/*        transform: translate(0, 0);*/
/*    }*/
/*    100% {*/
/*        transform: translate(24px, 0);*/
/*    }*/
/*}*/
/*table.nowrap th, table.nowrap td {*/
/*    white-space: nowrap;*/
/*}*/
/*table th.dt-left,*/
/*table td.dt-left {*/
/*    text-align: left;*/
/*}*/
/*table th.dt-center,*/
/*table td.dt-center,*/
/*table td.dataTables_empty {*/
/*    text-align: center;*/
/*}*/
/*table th.dt-right,*/
/*table td.dt-right {*/
/*    text-align: right;*/
/*}*/
/*table th.dt-justify,*/
/*table td.dt-justify {*/
/*    text-align: justify;*/
/*}*/
/*table th.dt-nowrap,*/
/*table td.dt-nowrap {*/
/*    white-space: nowrap;*/
/*}*/
/*table thead th,*/
/*table thead td,*/
/*table tfoot th,*/
/*table tfoot td {*/
/*    text-align: left;*/
/*}*/
/*table thead th.dt-head-left,*/
/*table thead td.dt-head-left,*/
/*table tfoot th.dt-head-left,*/
/*table tfoot td.dt-head-left {*/
/*    text-align: left;*/
/*}*/
/*table thead th.dt-head-center,*/
/*table thead td.dt-head-center,*/
/*table tfoot th.dt-head-center,*/
/*table tfoot td.dt-head-center {*/
/*    text-align: center;*/
/*}*/
/*table thead th.dt-head-right,*/
/*table thead td.dt-head-right,*/
/*table tfoot th.dt-head-right,*/
/*table tfoot td.dt-head-right {*/
/*    text-align: right;*/
/*}*/
/*table thead th.dt-head-justify,*/
/*table thead td.dt-head-justify,*/
/*table tfoot th.dt-head-justify,*/
/*table tfoot td.dt-head-justify {*/
/*    text-align: justify;*/
/*}*/
/*table thead th.dt-head-nowrap,*/
/*table thead td.dt-head-nowrap,*/
/*table tfoot th.dt-head-nowrap,*/
/*table tfoot td.dt-head-nowrap {*/
/*    white-space: nowrap;*/
/*}*/
/*table tbody th.dt-body-left,*/
/*table tbody td.dt-body-left {*/
/*    text-align: left;*/
/*}*/
/*table tbody th.dt-body-center,*/
/*table tbody td.dt-body-center {*/
/*    text-align: center;*/
/*}*/
/*table tbody th.dt-body-right,*/
/*table tbody td.dt-body-right {*/
/*    text-align: right;*/
/*}*/
/*table tbody th.dt-body-justify,*/
/*table tbody td.dt-body-justify {*/
/*    text-align: justify;*/
/*}*/
/*table tbody th.dt-body-nowrap,*/
/*table tbody td.dt-body-nowrap {*/
/*    white-space: nowrap;*/
/*}*/

/*!**/
/* * Table styles*/
/* *!*/
#table-grid {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    /*
     * Header and footer styles
     */
    /*
     * Body styles
     */
}
/*table thead th,*/
/*table tfoot th {*/
/*    font-weight: bold;*/
/*}*/

#table-grid thead th,
#table-grid thead td {
    padding: 20px 15px;
    border: none;
    font-family: var(--font-family-regular) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    /* identical to box height */

    /* Main Black */
    color: var(--black-100);


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
/*table thead th:active,*/
/*table thead td:active {*/
/*    outline: none;*/
/*}*/
/*table tfoot th,*/
/*table tfoot td {*/
/*    padding: 10px 10px 6px 10px;*/
/*    border-top: 1px solid rgba(0, 0, 0, 0.3);*/
/*}*/
#table-grid tbody tr {
    background-color: transparent;
    height: 50px;
}
/*table tbody tr.selected > * {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);*/
/*    color: rgb(255, 255, 255);*/
/*    color: rgb(var(--dt-row-selected-text));*/
/*}*/
/*table tbody tr.selected a {*/
/*    color: rgb(9, 10, 11);*/
/*    color: rgb(var(--dt-row-selected-link));*/
/*}*/
#table-grid tbody th,
#table-grid tbody td {
    padding: 15px;
}
/*table.row-border tbody th, table.row-border tbody td, table.display tbody th, table.display tbody td {*/
/*    border-top: 1px solid rgba(0, 0, 0, 0.15);*/
/*}*/
/*table.row-border tbody tr:first-child th,*/
/*table.row-border tbody tr:first-child td, table.display tbody tr:first-child th,*/
/*table.display tbody tr:first-child td {*/
/*    border-top: none;*/
/*}*/
/*table.cell-border tbody th, table.cell-border tbody td {*/
/*    border-top: 1px solid rgba(0, 0, 0, 0.15);*/
/*    border-right: 1px solid rgba(0, 0, 0, 0.15);*/
/*}*/
/*table.cell-border tbody tr th:first-child,*/
/*table.cell-border tbody tr td:first-child {*/
/*    border-left: 1px solid rgba(0, 0, 0, 0.15);*/
/*}*/
/*table.cell-border tbody tr:first-child th,*/
/*table.cell-border tbody tr:first-child td {*/
/*    border-top: none;*/
/*}*/
/*table.stripe > tbody > tr.odd > *, table.display > tbody > tr.odd > * {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);*/
/*}*/
/*table.stripe > tbody > tr.odd.selected > *, table.display > tbody > tr.odd.selected > * {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.923));*/
/*}*/
/*table.hover > tbody > tr:hover > *, table.display > tbody > tr:hover > * {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);*/
/*}*/
/*table.hover > tbody > tr.selected:hover > *, table.display > tbody > tr.selected:hover > * {*/
/*    box-shadow: inset 0 0 0 9999px #0d6efd !important;*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 1)) !important;*/
/*}*/
/*table.order-column > tbody tr > .sorting_1,*/
/*table.order-column > tbody tr > .sorting_2,*/
/*table.order-column > tbody tr > .sorting_3, table.display > tbody tr > .sorting_1,*/
/*table.display > tbody tr > .sorting_2,*/
/*table.display > tbody tr > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);*/
/*}*/
/*table.order-column > tbody tr.selected > .sorting_1,*/
/*table.order-column > tbody tr.selected > .sorting_2,*/
/*table.order-column > tbody tr.selected > .sorting_3, table.display > tbody tr.selected > .sorting_1,*/
/*table.display > tbody tr.selected > .sorting_2,*/
/*table.display > tbody tr.selected > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.919));*/
/*}*/
/*table.display > tbody > tr.odd > .sorting_1, table.order-column.stripe > tbody > tr.odd > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);*/
/*}*/
/*table.display > tbody > tr.odd > .sorting_2, table.order-column.stripe > tbody > tr.odd > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);*/
/*}*/
/*table.display > tbody > tr.odd > .sorting_3, table.order-column.stripe > tbody > tr.odd > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);*/
/*}*/
/*table.display > tbody > tr.odd.selected > .sorting_1, table.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.954));*/
/*}*/
/*table.display > tbody > tr.odd.selected > .sorting_2, table.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.947));*/
/*}*/
/*table.display > tbody > tr.odd.selected > .sorting_3, table.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.939));*/
/*}*/
/*table.display > tbody > tr.even > .sorting_1, table.order-column.stripe > tbody > tr.even > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);*/
/*}*/
/*table.display > tbody > tr.even > .sorting_2, table.order-column.stripe > tbody > tr.even > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);*/
/*}*/
/*table.display > tbody > tr.even > .sorting_3, table.order-column.stripe > tbody > tr.even > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);*/
/*}*/
/*table.display > tbody > tr.even.selected > .sorting_1, table.order-column.stripe > tbody > tr.even.selected > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.919));*/
/*}*/
/*table.display > tbody > tr.even.selected > .sorting_2, table.order-column.stripe > tbody > tr.even.selected > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.911));*/
/*}*/
/*table.display > tbody > tr.even.selected > .sorting_3, table.order-column.stripe > tbody > tr.even.selected > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.903));*/
/*}*/
/*table.display tbody tr:hover > .sorting_1, table.order-column.hover tbody tr:hover > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);*/
/*}*/
/*table.display tbody tr:hover > .sorting_2, table.order-column.hover tbody tr:hover > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);*/
/*}*/
/*table.display tbody tr:hover > .sorting_3, table.order-column.hover tbody tr:hover > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);*/
/*}*/
/*table.display tbody tr:hover.selected > .sorting_1, table.order-column.hover tbody tr:hover.selected > .sorting_1 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.982));*/
/*}*/
/*table.display tbody tr:hover.selected > .sorting_2, table.order-column.hover tbody tr:hover.selected > .sorting_2 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.974));*/
/*}*/
/*table.display tbody tr:hover.selected > .sorting_3, table.order-column.hover tbody tr:hover.selected > .sorting_3 {*/
/*    box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);*/
/*    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected, 0.962));*/
/*}*/
/*table.no-footer {*/
/*    border-radius: 5px;*/
/*    !*border-bottom: 1px solid rgba(0, 0, 0, 0.3);*!*/
/*}*/
/*table.compact thead th,*/
/*table.compact thead td,*/
/*table.compact tfoot th,*/
/*table.compact tfoot td,*/
/*table.compact tbody th,*/
/*table.compact tbody td {*/
/*    padding: 4px;*/
/*}*/

/*table th,*/
#table-grid td {
    box-sizing: content-box;
    font-family: var(--font-family-regular)!important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    color: var(--black-100);
}

/*!**/
/* * Control feature layout*/
/* *!*/
/*@media (min-width: 1900px) {*/
/*    .dataTables_wrapper {*/
/*        min-height: 80% !important;*/
/*    }*/
/*}*/
/*@media (min-width: 1440px) {*/
/*    .dataTables_wrapper {*/
/*        min-height: 80% !important;*/
/*    }*/
/*}*/
/*@media (min-width: 1366px) {*/
/*    .dataTables_wrapper {*/
/*        min-height: 85% !important;*/
/*    }*/
/*}*/
/*.dataTables_wrapper {*/
/*    position: relative;*/
/*    clear: both;*/
/*    padding: 0;*/
/*    margin: 10px 0 40px 0;*/
/*    min-height: 65%;*/
/*}*/
/*.dataTables_length {*/
/*    float: left;*/
/*    padding-top: 25px*/
/*}*/
/*.dataTables_length select {*/
/*    border-radius: 3px;*/
/*    padding: 3px;*/
/*    background-color: var(--white);*/
/*    border: none;*/
/*    font-family: var(--font-family-regular);*/
/*    color: var(--primary-color);*/
/*}*/
/*.dataTables_filter {*/
/*    float: right;*/
/*    text-align: right;*/
/*}*/
/*.dataTables_filter input {*/
/*    border: 1px solid #aaa;*/
/*    border-radius: 3px;*/
/*    padding: 5px;*/
/*    background-color: transparent;*/
/*    margin-left: 3px;*/
/*    font-family: var(--font-family-regular);*/
/*}*/
.table-react table {
    width: 100% !important;
}
.dataTables_info {
    clear: both;
    float: left;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px;
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* Text Grey */
    color: #596676!important;
}
.dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 20px;
    padding-bottom: 30px;
}
.paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 35px;
    width: 70px;
    max-width: fit-content;
    padding: 0.5em 0.5rem;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    background: var(--grey-white);
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 700;
    font-size: 12px!important;

    /* Text Grey */
    color: var(--dark);
    height: 32px;
    border-radius: 5px;
    margin: 0 3px;
    border-color: transparent;

}
.paginate_button:hover {
    color: var(--white) ;
    background: var(--red) ;
}
.paginate_button:disabled:hover {
    color: var(--white) !important;
    border: 1px solid transparent;
    background: var(--grey-white-2) !important;
    pointer-events: none;
}
button.disabled:hover {
    cursor: default;
    color: var(--white) !important;
    border: 1px solid transparent;
    background: var(--grey-white-2);
    box-shadow: none;
}
.paginate-button-active {
    color: var(--white) !important;
    background: var(--red) !important;
}
#table-grid tbody {
    background-color: var(--white) !important;
}

.paginate_button:active {
    outline: none;
    background-color: #2b2b2b;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%); /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%); /* FF3.6+ */
    background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%); /* IE10+ */
    background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%); /* Opera 11.10+ */
    background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%); /* W3C */
    box-shadow: inset 0 0 3px #111;
}
.dataTables_paginate .ellipsis {
    padding: 0 1em;
}
.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_processing,
.dataTables_paginate {
    color: inherit;
}
/*.dataTables_scroll {*/
/*    clear: both;*/
/*}*/
/*.dataTables_scroll div.dataTables_scrollBody {*/
/*    -webkit-overflow-scrolling: touch;*/
/*}*/
/*.dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {*/
/*    vertical-align: middle;*/
/*}*/
/*.dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,*/
/*.dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,*/
/*.dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {*/
/*    height: 0;*/
/*    overflow: hidden;*/
/*    margin: 0 !important;*/
/*    padding: 0 !important;*/
/*}*/
/*.dataTables_wrapper.no-footer .dataTables_scrollBody {*/
/*    border-bottom: 1px solid rgba(0, 0, 0, 0.3);*/
/*}*/
/*.dataTables_wrapper.no-footer div.dataTables_scrollHead table,*/
/*.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {*/
/*    border-bottom: none;*/
/*}*/
/*.dataTables_wrapper:after {*/
/*    visibility: hidden;*/
/*    display: block;*/
/*    content: "";*/
/*    clear: both;*/
/*    height: 0;*/
/*}*/

/*@media screen and (max-width: 767px) {*/
/*    .dataTables_info,*/
/*    .dataTables_paginate {*/
/*        float: none;*/
/*        text-align: center;*/
/*    }*/
/*    .dataTables_paginate {*/
/*        margin-top: 0.5em;*/
/*    }*/
/*}*/
/*@media screen and (max-width: 640px) {*/
/*    .dataTables_length,*/
/*    .dataTables_filter {*/
/*        float: none;*/
/*        text-align: center;*/
/*    }*/
/*    .dataTables_filter {*/
/*        margin-top: 0.5em;*/
/*    }*/
/*}*/

/*.spacing-td {*/
/*    padding: 20px 0!important;*/
/*}*/
table {
    border-collapse: separate;
    border-spacing: 0;
}

thead th:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 0
}
thead th:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 20px
}

tr:first-child td:first-child { border-top-left-radius: 0; }
tr:first-child td:last-child { border-top-right-radius: 0; }

tr:last-child td:first-child { border-bottom-left-radius: 20px; }
tr:last-child td:last-child { border-bottom-right-radius: 20px; }

tr:first-child td { border-top-style: solid; }
tr td:first-child { border-left-style: solid; }

button:disabled {
    background: var(--grey-white-2);
    border: 1px solid #0000;
    box-shadow: none;
    color: var(--white);
    cursor: default;
}
.table-react {
    width: 100%;
    padding-left: unset !important;
}

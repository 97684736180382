/* Navigation: 14%
   Header: 9%
 */
.login-background {
    height: 100vh;
    width: 100%;
}

.app-background {
    min-height: 100vh;
    width: 80% !important;
    background-color: #F2F2F2 !important;
    max-height: 300vh;
}

.sidebar {
    width: 20% !important;
}

.sidebar-background {
    height: 100vh;
    width: 100%;
    background-color: #F2F2F2 !important;
    overflow-y: hidden;
}

.background-1 {
    background: transparent;
}

.background-2 {
    background: transparent;
    width: 100%
}

.card-body {
    margin: 10% 0 10% 0;
    background-color: var(--white);
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 50px 150px 50px 120px;
    gap: 190px;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
}

.card-body-template {
    margin: 5% 0 5% 0;
    background-color: var(--white);
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 24px;
    gap: 190px;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
    display: block !important;
    height: 230px;
    max-height: 230px
}

.card-body-calendar {
    margin: 0 0 40px 0 !important;
    background-color: var(--white);
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 0 !important;
    gap: 190px;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
}

.card-body-default {
    margin: 0 0 0 0;
    background-color: transparent !important;
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 0;
    gap: 190px;
    border: none !important;
    /*border-radius: 20px !important;*/
    /*box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;*/
}
.card-body-calendar-elements {
    margin: 0 0 0 0;
    background-color: transparent !important;
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 0;
    gap: 5px;
    border: none !important;
    /*border-radius: 20px !important;*/
    /*box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;*/
}

.card-body-letters {
    background-color: transparent !important;
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 0;
    /*gap: 190px;*/
    border: none !important;
    border-radius: 20px !important;
    /*box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;*/
}

.card-body-letters-preview {
    background-color: var(--white) !important;
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 24px !important;
    /*gap: 190px;*/
    border: none !important;
    border-radius: 20px !important;
    margin: 24px 24px 0 0px !important;
    /*box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;*/
}

.card-body-letters .header-div {
    background-color: var(--primary-100);
    color: var(--white);
    height: 60px;
    padding: 24px !important;
    border-radius: 20px 20px 0 0;
    border: 10px;
    gap: 300px;
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.card-body-letters .body-div {
    background-color: var(--white);
    padding: 24px 20px 0 24px !important;
    border-radius: 0 0 20px 20px;
    max-height: fit-content;
    min-height: 550px;
}

.card-body-letters .body-div-email {
    background-color: var(--white);
    padding: 24px 8px 0 24px !important;
    border-radius: 0 0 20px 20px;
    max-height: fit-content;
    min-height: 150px;
}

.scroll-div {
    max-height: 450px;
    overflow-y: scroll;
}

.logo {
    height: 68px;
    width: 186px;
}

.main_image {
    width: 100%;
    height: 100vh;
    padding: 50px 80px;
}

.margin-top {
    margin: -120px 20px -160px 20px;
}

.header {
    font-family: var(--font-family-bold);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--black-100);
}

.sub-header {
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--black-75);
    margin: 0 0 30px 0 !important;
}

.sub-footer {
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--black-75);
}

.login-form {
    margin: 10px 0 8px 0;
}

.input-login {
    border-radius: 10px;
    border: 1px solid var(--black-25);
    background: var(--white);
    width: 100%;
    height: 50px;
    padding: 12px 16px 0 40px;
    font-family: var(--font-family-regular);
    color: var(--black-100, #666D73);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.input-error {
    color: var(--red-error);
    border: 1px solid var(--red-error);
    border-radius: 10px;
    background: var(--white);
    width: 100%;
    height: 50px;
    padding: 12px 16px 0 40px;
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input-valid-form {
    color: var(--black-100, #666D73);
    border: 1px solid var(--black-75);
    border-radius: 10px;
    background: var(--white);
    width: 100%;
    height: 60px;
    padding: 12px 16px 0 40px;
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.form-check-label {
    padding-top: 2px
}

.input-error-form {
    border-radius: 10px;
    border: 1px solid var(--black-25);
    background: var(--white);
    width: 100%;
    height: 60px;
    padding: 12px 16px 0 40px;
    font-family: var(--font-family-regular);
    color: var(--black-100, #666D73);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.input-document {
    border-radius: 10px;
    border: 1px solid var(--black-25);
    background: var(--white);
    width: 100%;
    height: 50px;
    padding: 0px 16px 0 40px;
    font-family: var(--font-family-regular);
    color: var(--black-100, #666D73);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.input-spacing-document {
    padding-left: 16px;
    /*padding-top: 20px;*/
    /*height: 60px*/
}

.form-label {
    position: absolute;
    padding: 5px 0 0 42px;
    color: var(--black-50, #979D9F);
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-label {
    padding: 5px 0 0 42px;
    color: var(--black-50, #979D9F);
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.icon {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.login-icon {
    position: absolute;
    color: var(--primary-100);
    margin: 10px 10px 0 10px;
}

.form-icon {
    position: absolute;
    color: var(--primary-100);
    margin: 15px 10px 0 10px;
}

.password-icon {
    position: absolute;
    display: flex;
    color: var(--primary-100);
    margin: 15px 0 0 75%;
}

.error-icon {
    /*width: 20px;*/
    /*height: 20px;*/
    display: flex;
    color: var(--red-error);
}

.button-active {
    background: var(--primary-100);
    color: var(--white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.button-inactive {
    background: var(--primary-25) !important;
    color: var(--white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.button-transparent {
    background: transparent;
    color: var(--black-100);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 1px solid var(--primary-100)
}

.button-transparent-inactive {
    background: transparent;
    color: var(--black-25);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 1px solid var(--primary-50)
}

.button-transparent-active {
    background: transparent;
    color: var(--black-100);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 1px solid var(--primary-100);
    cursor: pointer;
}

.button-transparent-active:active {
    background: var(--primary-25);
    color: var(--white);
}
.button-transparent-active-red {
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
}

.button-transparent-active-red:active {
    background: var(--red-50) !important;
    color: var(--white) !important;
}

.login-button {
    margin: 40px 0 10px 0;
}

.copyright {
    margin: 10px 0;
}

.error-message {
    margin: 10px 0;
    color: var(--red-error);
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%
}

.float-left-child {
    float: left;
}

.float-right-child {
    float: right;
}
/*.child-width-certificate {*/
/*    width: 37%;*/
/*}*/
.certificate-button-layout {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    /*width: 40%*/
}

.message {
    margin: 0 0 0 5px;
    color: var(--red-error);
    width: 80%;
    height: 67px;
}

.message-1 {
    margin: 5px 0 0 5px;
}

.message-2 {
    color: var(--black-50);
}

.message-3 {
    color: var(--red);
    font-weight: 600;
}

.message-4 {
    color: var(--primary-100);
    font-weight: 600;
    cursor: pointer;
}

.message-5 {
    color: var(--black-100);
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /*margin-left: 15%;*/
    margin: 0 10px 0 0 !important;
}

.message-6 {
    color: var(--red);
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /*margin-left: 15%;*/
    margin: 0 10px 0 0 !important;
}
.event-name {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}
.event-details{
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.counter {
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0 30px 0;
}

.form-check {
    color: var(--black-75);
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 18px 0 !important;
}

.form-check-input {
    margin: 0 18px 0 0;
    height: 0px;
    border: 1px solid var(--primary-100) !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: var(--primary-100) !important;
    border-color: var(--primary-100) !important;
}

.form-check-input:checked[type=radio] {
    /*--bs-form-check-bg-image: url();*/
}

.separator {
    margin-left: 10px;
}

.otp-input {
    width: 20% !important;
    height: 60px;
    border-radius: 8px;
    border: 1px solid var(--black-25);
    color: var(--black-100);
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

input[type=radio]:checked + label {
    color: var(--black-100)
}

.client-logo {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 10px;
    position: relative;
    margin: 0 0 0 10px
}

.client-logo:hover {
    border: 1px solid var(--primary-100);
}

.client-logo-select {
    border: 1px solid var(--primary-100);
}

.form-client-logo {
    position: absolute;
    z-index: 10;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IlJhZGlvIj4KPGNpcmNsZSBpZD0iRWxsaXBzZSA4NSIgY3g9IjgiIGN5PSI4IiByPSI4IiBmaWxsPSIjNERBOEI3Ii8+CjxnIGlkPSJFbGxpcHNlIDg1XzIiIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfMTQyNl80ODI4MikiPgo8Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iNCIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfMTQyNl80ODI4MiIgeD0iMiIgeT0iMyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMSIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMC4yIDAgMCAwIDAgMC4yIDAgMCAwIDAgMC4yIDAgMCAwIDAuMDQgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd18xNDI2XzQ4MjgyIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93XzE0MjZfNDgyODIiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==")
}

.sidebar-icon {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    cursor: pointer;
}

.sidebar-icon-form {
    height: 20px;
    width: 20px;
    color: var(--black-50);
    cursor: pointer;
}

.sidebar-icon-text {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    cursor: pointer;
    margin: -4px 0 0 4px;
}

.small {
    height: 16px;
    width: 16px;
    margin: -4px 0 0 0;
}

.sidebar-icon-input {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    margin: 8px 0 0 0;
    cursor: pointer;
}

.sidebar-icon-1 {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    margin: 4px 0 0 0;
    cursor: pointer;
}
.button-icon {
    height: 34px;
    width: 34px;
    color: var(--white);
    margin: 4px 0 0 0;
    cursor: pointer;
}

.nav-body {
    /*height: 14%;*/
    width: 100%;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-white);
}

.nav-container {
    margin: 0 15px !important;
}

.app-spacing {
    padding: 0 !important;
}

.school-name-label {
    color: var(--black-75, #666D73);
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.school-name-input {
    color: var(--black-100, #333B44);
    font-family: var(--font-family-regular);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
}

.change-client {
    color: var(--black-25, #333B44);
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
}

.menu-row {
    align-items: center;
    background-color: transparent
}

.nav-row {
    align-items: center;
    background-color: transparent;
    width: 45%;
}

.user-name {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--black-100);
    text-align: left;
    padding: 0 5px;
}

.padding-last-item {
    padding-right: 0 !important;
}

.page-footer {
    text-align: center;
    height: 45px;
    width: 100% !important;
    /*padding: 0 30px;*/
    margin: 0 0 10px 0;
}

.page-inner-footer {
    border-top: 1px solid var(--grey-white-2);
    padding: 20px 0;
}

.footer-text {
    color: var(--black-75, #666D73);
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.footer-inner-text-1 {
    color: var(--red);
    font-weight: 700;
}

.footer-inner-text-2 {
    color: var(--dark);
    font-weight: 700;
}

.app-body {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 86vh;
}
.app-body-teacher {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    min-height: 105vh;
    max-height: fit-content;
}

.app-body-document {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 56vh;
    /*max-height: 300vh;*/
}

.app-body-super-admin {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 75vh;
    /*max-height: 300vh;*/
}
.app-body-analytics {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 65vh;
    /*max-height: 300vh;*/
}
.app-body-teacher-list {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    /*min-height: fit-content;*/
    height: 75vh;
    /*max-height: 300vh;*/
}

.app-body-landing {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 76vh;
    /*max-height: 300vh;*/
}

.app-body-template {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 76vh;
    /*max-height: 300vh;*/
}

.app-body-template-letters {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 65vh;
    /*max-height: 300vh;*/
}

.app-body-calendar {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 76vh;
    /*max-height: 300vh;*/
}

.app-body-certificate {
    margin: 0 12px 0 15px !important;
    overflow-y: scroll;
    height: 66vh;
    /*max-height: 300vh;*/
}

.app-body-add-document {
    margin: 0 12px 0 15px !important;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /*max-height: 300vh;*/
}

.app-body-send-process {
    margin: 0 12px 0 15px !important;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /*max-height: 300vh;*/
}

.card-body-metrics {
    margin: 10% 0 10% 0;
    background-color: var(--white);
    width: 100%;
    font-family: var(--font-family-regular);
    padding: 10px;
    gap: 190px;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
}
.card-employee-view {
    margin: 10% 0 2% 0;
    background-color: var(--white);
    width: 100%;
    height: fit-content;
    font-family: var(--font-family-regular);
    padding: 20px 10px 10px 20px !important;
    /*gap: 10px;*/
    border: none !important;
    border-radius: 10px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
}

.card-blue {
    padding: 12px 0;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid var(--primary-100);
    background: linear-gradient(90deg, #FFF 0%, #FFF 51.01%, #E8FEFF 99.93%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.card-red {
    padding: 12px 0;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid var(--red);
    background: linear-gradient(90deg, #FFF 0%, #FFF 51.01%, #FFE8E8 99.93%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.metric-logo {
    padding: 10px 0;
    /*height: 90%;*/
}

.metric-header {
    color: var(--black-100);
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

.metric-data {
    color: var(--black-75);
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.metric-body {
    color: var(--black-100);
    font-family: var(--font-family-regular);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 51px */
    letter-spacing: 1.5px;
    margin-top: 10px;
}

.dashboard-card {
    margin: 0 0 5% 0;
    padding: 20px 5px;
    background-color: var(--white);
    width: 100%;
    font-family: var(--font-family-regular);
    gap: 190px;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: 16px 16px 32px 0px rgba(0, 0, 0, 0.10) !important;
}

.circle-div {
    background-color: var(--light-blue);
    border-radius: 50px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-div-image {
    display: flex;
    width: 24px;
    height: 24px;
}
.circle-div-image-analytics {
    display: flex;
    width: 40px;
    height: 40px;
}
.circle-div-image-analytics-2 {
    display: flex;
    width: 20px;
    height: 20px;
}

.card-dashboard-header {
    color: var(--black-100);
    font-family: var(--font-family-regular);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    padding: 5px 0 0 12px;
}

.view-all-text {
    color: var(--black-100);
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.view-all-icon {
    color: var(--red);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.headings {
    display: flex;
    width: 100%;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-white, #E9E9E9);
    background: var(--white, #FFF);
    cursor: pointer;
    /*height: 9%;*/
}

.headings-letters {
    display: flex;
    width: 100%;
    margin: 0 30px;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    cursor: pointer;
    height: 100%;
}

.headings-multi-items {
    display: flex;
    width: 100%;
    background: var(--white, #FFF);
    border-bottom: 1px solid var(--grey-white, #E9E9E9);
    /*padding: 12px 30px;*/
    /*height: 9%;*/
}

.header-button {
    margin: 10px 30px !important;
    width: 30%
}

.heading-underline {
    border-bottom: 3px solid var(--red);
}

.heading-title {
    color: var(--black-100, #333B44);
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    padding: 10px 10px 10px 0
}

.dataTables_wrapper > .row {
    background-color: transparent !important;
}

.margin-left {
    margin-left: 5px !important
}

.margin-right {
    margin-right: 5px !important
}

.paginate-arrow {
    color: var(--primary-100);
    /*height: 24px;*/
    /*width: 24px*/
}

.add-button {
    cursor: pointer;
    border-radius: 8px;
    background: var(--primary-100, #4DA8B7);
    display: flex;
    height: 45px;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--white, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.add-button-transparent {
    cursor: pointer;
    border: 1px solid var(--primary-100);
    border-radius: 8px;
    background: transparent;
    display: flex;
    height: 45px;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--primary-100);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.reset-filter-button {
    height: 95%
}

.submit-buttons {
    margin-bottom: 15px !important;
    margin-top: 35px !important;
}

.submit-buttons-proposal {
    margin-bottom: 95px !important;
    margin-top: 45px !important;
}

.submit-buttons-modal {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}

.submit-buttons-upload {
    margin-bottom: 15px !important;
}

.input-spacing {
    padding-left: 16px;
    padding-top: 20px;
    height: 60px
}


.textarea-spacing {
    padding-left: 16px;
    margin-top: 5px;
    height: 60px
}

.form-spacing {
    padding-left: 16px;
    padding-top: 10px
}

.asterics {
    color: var(--red);
    font-size: 12px
}

.document-form {
    margin: 24px 0 0 0;
}
.document-form-toggle {
    margin: 24px 0 0 0;
    padding: 16px 16px;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    border: 1px solid var(--black-25)
}
.document-form-toggle-active {
    margin: 24px 0 0 0;
    padding: 16px 16px;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    border: 1px solid var(--black-75)
}

.calendar-form {
    margin: 24px 0 0 0;
}

.document-form-textarea-error {
    margin: 24px 0 0 0;
    border-radius: 10px;
    border: 1px solid var(--black-25);
    background: var(--white);
    width: 100%;
    height: 100px;
    padding: 5px 0 0 0;
    font-family: var(--font-family-regular);
    color: var(--black-100, #666D73);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.document-form-textarea {
    margin: 24px 0 0 0;
    border-radius: 10px;
    border: 1px solid var(--black-75);
    background: var(--white);
    width: 100%;
    height: 100px;
    padding: 5px 0 0 0;
    font-family: var(--font-family-regular);
    color: var(--black-100, #666D73);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.textarea-input-error {
    height: 60px;
    width: 100%;
    border: none !important;
}

.row {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.icon-down-arrow {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    margin: 12px 0 0 0;
    cursor: pointer;
}

.icon-down-arrow-input {
    height: 24px;
    width: 24px;
    color: var(--black-50);
    margin: 24px 0 0 0;
    cursor: pointer;
}

.file-box {
    margin: 30px 0 10px 0 !important;
    padding: 35px !important;
    background: var(--white);
    border: 1px solid var(--black-25);
    border-radius: 12px
}

.file-box-small {
    margin: 30px 0 10px 0 !important;
    padding: 5px !important;
    background: var(--white);
    border: 1px solid var(--black-75);
    border-radius: 12px;
    cursor: pointer;
}

.icon-upload {
    width: 48px;
    height: 48px;
}

.span-upload {
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    color: var(--black-100)
}

.span-upload-process {
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    color: var(--primary-100)
}
.span-client-name {
    text-align: center;
    font-family: var(--font-family-regular);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--black-100)
}
.width-80 {
    width: 80%
}

.span-upload-template {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--primary-100) !important;
}

.doc-icon {
    padding-top: 12px;
    padding-left: 0px !important;
}

.doc-name-outer {
    margin-left: -5% !important;
}

.doc-size {
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--black-100);
    padding: 8px 0 0 0 !important;
}

.padding-right-unset {
    padding-right: 0 !important;
}

.padding-left-unset {
    padding-left: 0 !important;
}

.inactive {
    opacity: 50%;
}

.active-border {
    border: 1px solid var(--black-75) !important;
    border-radius: 10px !important
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding-top {
    padding-top: 20px
}

.padding-top-subheader {
    padding-top: 15px
}

.dot {
    color: var(--red);
    font-size: 30px;
    line-height: 1px;
    vertical-align: top;
}

.client-div input[type="radio"] {
    border: none !important;
    padding: unset !important;
}

.client-div .form-check {
    margin: 0 20px 0 0 !important;
}

.centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.client-div input[type=radio]:checked + label {
    border: 1px solid var(--primary-100);
    border-radius: 10px
}

.empty-table {
    padding: 150px;
}

.text-blue {
    color: var(--primary-100);
}
.text-active {
    color: var(--green);
}

.text-inactive {
    color: var(--red) !important;
}

.text-error {
    color: var(--red-error)
}

.text-yellow {
    color: var(--yellow)
}

.text-blue {
    color: var(--primary-100) !important;
}

.sub-header-page {
    padding: 20px 0 0 5px !important
}

.year-text {
    font-family: var(--font-family-regular);
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-100);
}

.calendar-view {
    padding: 0 15px !important;
}

.calendar-buttons {
    padding: 0 15px 0 40px !important;
    display: flex;
    justify-content: flex-end
}

@media (max-width: 1025px) {
    .password-icon {
        margin: 15px 0 0 70% !important;
    }

    .card-body {
        width: 130% !important;
    }

    .main_image {
        height: 90vh !important;
    }

    .client-div .form-check {
        margin: 0 10px 0 0 !important;
    }

    .message-5 {
        margin: 0 0 0 5px !important;
    }
}

@media (max-width: 1367px) {
    .card-body {
        margin: 30% 0 10% 0 !important;
    }

    .password-icon {
        margin: 15px 0 0 75% !important;
    }

    .client-div .form-check {
        margin: 0 0 0 0 !important;
    }

    .message-5 {
        margin: 0 0 0 5px !important;
    }
}
@media (min-width: 1366px) {
    /*.card-body {*/
    /*    margin: 25% 0 5% 0 !important;*/
    /*    padding-top: 40px !important;*/
    /*    width: 100% !important;*/
    /*    height: 650px !important;*/
    /*}*/
    /*.main_image {*/
    /*    width: 100%;*/
    /*    height: 85vh;*/
    /*    padding: 50px 80px;*/
    /*}*/
    /*.app-body-document {*/
    /*    height: 62vh !important;*/
    /*}*/
    /*.app-body-certificate {*/
    /*    height: 68vh !important;*/
    /*}*/
}

@media (min-width: 1440px) {
    /*.card-body {*/
    /*    margin: 25% 0 5% 0 !important;*/
    /*    padding-top: 40px !important;*/
    /*    width: 100% !important;*/
    /*    height: 650px !important;*/
    /*}*/

    /*.app-body-document {*/
    /*    height: 62vh !important;*/
    /*}*/
    /*.app-body-certificate {*/
    /*    height: 68vh !important;*/
    /*}*/
}

@media (min-width: 1900px) {
    .card-body {
        margin: 25% 0 5% 0 !important;
        padding-top: 40px !important;
        width: 100% !important;
        height: 750px !important;
    }

    .password-icon {
        margin: 15px 0 0 80% !important;
    }

    .client-div .form-check {
        padding: 0 10px 0 0 !important;
    }

    .message-5 {
        margin: 0 0 0 5px !important;
    }

    .navbar {
        width: 100% !important;
    }
    .app-body-document {
        height: 62vh !important;
    }
    .app-body-certificate {
        height: 68vh !important;
    }
    .container {
        max-width: 100% !important;
    }

    .client-div .form-check-label {
        width: 125%
    }
}

.label-form-checkbox {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

}

.form-checkbox {
    width: 200px;
    padding: 10px 0
}

.hardcopy-div .form-checkbox {
    width: 150px !important;
}

.hardcopy-div .form-check-input {
    width: 20px !important;
    height: 20px !important;
}

.wrapper-editor {
    border: 1px solid var(--black-75);
    border-radius: 10px
}

.wrapper-editor-error {
    border: 1px solid var(--black-25);
    border-radius: 10px
}

.template-check .form-check-label {
    font-family: var(--font-family-regular) !important;
    color: var(--black-100) !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 5px
}

.template-check .form-check-input {
    height: 20px !important;
    width: 20px !important;
}

.letter-user-department {
    border-right: 2px solid var(--grey-white);
}

.error-message-div {
    background: var(--red-10);
    padding: 12px 16px;
    border-radius: 10px;
    font-family: var(--font-family-regular);
    color: var(--red);
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.button-size {
    width: 49%
}

.button-size-50 {
    width: 49%
}

.button-size-25 {
    width: 25%
}

.email-list-div {
    border-top: 1px solid var(--grey-white-2);
    margin-top: 24px !important;
}

.email-list-div span {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.notice-preview-heading {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.notice-preview-body {
    width: 100%;
    padding: 24px;
    border-radius: 10px;
    background: var(--blue-50);
    margin-top: 24px;
}



.preview-table-description {
    background: var(--white);
    padding: 20px 24px !important;
    border-radius: 20px;
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    height: fit-content
}

.description-heading {
    font-family: var(--font-family-regular);
    color: var(--black-75);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.description-data {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.description-data-red {
    color: var(--red-error) !important;
}

.description-data-green {
    color: var(--green) !important;
}

.email-information {
    border-top: 1px solid var(--grey-white-2);
    margin: 10px 0 10px 15px !important;
    padding: 30px 0 0 0 !important;
    width: 98% !important;
}

.description-div {
    border-bottom: 1px solid var(--grey-white);
    padding-bottom: 16px;
}

.preview-table-data {
    padding: 18px 0;
}

.preview-table-body-input {
    border-radius: 10px;
    padding: 8px 16px !important;
    border: 1px solid var(--black-75)
}
.preview-table-body-input-no-border {
    border: none!important;
}

.preview-table-body-error {
    border-radius: 10px;
    padding: 8px 16px !important;
    border: 1px solid var(--black-25)
}

#process_template_table .template-card-description {
    padding: 0 16px !important;
}

#process_template_table .template-card-title {
    padding-bottom: 8px !important;
}

.border-bottom-div {
    border-bottom: 1px solid var(--grey-white-2)
}

.card-process {
    background-color: var(--white);
    padding: 15px !important;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px #0000000D;

}

.card-process-manage {
    background-color: var(--white);
    padding: 15px 20px !important;
    border-radius: 20px;
    box-shadow: 0px 4px 20px 0px #0000000D;
}

.task-view {
    margin: 20px 0 10px 0 !important;
    padding: 10px 0 0 0 !important;
    border-top: 1px solid var(--grey-white)
}
.react-table-empty {
    text-align: center;
    padding: 80px;
    background: var(--white);
    width: 100%
}
.empty-table-text {
    font-family: var(--font-family-regular);
    font-size: 24px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: center;
    color: var(--black-100);
    padding: 24px;
    width: 100%
}
.react-table-empty-card {
    text-align: center;
    padding: 0px 25%;
    background: transparent !important;
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
}
.react-calendar-empty {
    text-align: center;
    padding: 100px;
    background: transparent;
    width: 100%;
    height: 85%
}
.app-body-template-letters-padding {
    padding-left: 12px!important;
}
.reset-button {
    margin: 15px;
    color: var(--primary-100)
}
.react-table-pagination {
    float: right;
    text-align: right;
    padding-top: 20px;
    padding-bottom: 30px;
}

.page-button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 35px;
    width: 70px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 0.5em 0.5rem;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    background: var(--grey-white);
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    color: var(--dark);
    height: 32px;
    border-radius: 5px;
    margin: 0 3px;
    border: transparent;
}

.page-button:hover {
    background-color: var(--black-75);
    color: var(--white)

}

.page-button.active {
    color: var(--white) !important;
    border: none;
    border-radius: 5px;
    background: var(--red) !important;
    width: 32px;
    height: 32px;
    font-family: var(--font-family-regular);
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px;
}

.page-button.disabled {
    color: #ccc;
    cursor: not-allowed;
}
.react-table-pagination button {
    border: transparent!important;
}
.html-viewer ul {
    list-style-type: disc; /* Use disc, circle, or square */
    padding-left: 20px; /* Adjust as needed */
}

.html-viewer ol {
    list-style-type: decimal; /* Use decimal, decimal-leading-zero, lower-roman, upper-roman, lower-alpha, upper-alpha */
    padding-left: 20px; /* Adjust as needed */
}

.html-viewer ul ul,
.html-viewer ol ul {
    list-style-type: circle; /* Nested unordered list style */
}

.html-viewer ul ol,
.html-viewer ol ol {
    list-style-type: lower-alpha; /* Nested ordered list style */
}
.preview-table {
    min-width: 100%;
    max-width: fit-content;
    overflow-x: auto;
}
.preview-table-header {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    background: var(--primary-25);
    padding: 20px 24px !important;
    border-radius: 20px 20px 0 0;
    display: flex;  /* This turns on flexbox */
    /*justify-content: space-around;*/
    min-width: fit-content;
    max-width: fit-content !important;
}
.preview-table-body {
    background: var(--white);
    padding: 20px 24px !important;
    border-radius: 0;
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    max-height: 800px;
    overflow-y: scroll;

    /*justify-content: space-around;*/
    min-width: fit-content;
    max-width: fit-content !important;
}
.cell {
    min-width: 300px;
    max-width: fit-content !important;
    display: flex;  /* Makes the box a flex container */
    /*justify-content: center;  !* Horizontally center the content *!*/
    align-items: center;  /* Vertically center the content */
    text-align: center;
    flex-wrap: nowrap;
}
.preview-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px; /* Adjust as needed to control the spacing between rows */
}

.cell-row {
    min-width: 300px;
    max-width: fit-content !important;
    padding: 5px; /* Adjust as needed */
    /*border: 1px solid #ccc; !* Optional: Add borders *!*/
    /*margin-right: 10px; !* Optional: Add margin between cells *!*/
}
.download-icon {
    margin: 0 auto;
    text-align: right;
    color: var(--primary-100);
    font-size: 20px;
    padding: 10px 0;
}
.client-td {
    padding-bottom: 10px !important;
}
.client-question {
    font-family: var(--font-family-regular);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--black-75)
}
.client-answer {
    font-family: var(--font-family-regular);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: var(--black-100)
}
.toggle-text {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: var(--black-100);
    align-content: center;
    width: 80%;
}
.span-error-page {
    font-family: var(--font-family-regular);
    padding: 10px
}
.span-error-page:hover {
    font-weight: 700;
    text-decoration: underline;
}
.toggle-style {
    background: transparent !important;
    border: none !important;
}
.dropdown-items {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 19.5px;
    text-align: left;
    color: var(--black-100) !important;
    margin: 3% 0 !important;
}
.dropdown-items:hover {
    background: transparent;
}
.dropdown-menu-show {
    width: fit-content!important;
    padding: 15% 0 !important;
    box-shadow: 0px 4px 30px 0px #232323;
    border: none !important;
}
.opacity-60 {
    opacity: 0.6;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-40 {
    opacity: 0.4;
}
.display-none {
    display: none
}
.image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background: var(--primary-100)
}
.image-button img {
    width: 80%;
    height: 80%;
}
.mfa-icon {
    position: absolute;
    z-index: 99;
    margin-left: 50%;
    margin-top: 2%;
    color: var(--red)
}
.mfa-image {
    position: relative;
}
.employee-sub-header {
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    padding: 10px 0 20px 0
}
.employee-sub-header-icon {
    width: 24px;
    height: 24px;
    color: var(--primary-100)
}
.sort-icon {
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.complete-button {
    border: 1px solid var(--primary-100);
    border-radius: 10px;
    padding: 5px 10px !important;
    width: fit-content;
}
.letters-emails-div {
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid var(--red);
    border-radius: 15px;
}
.letters-emails-span {
    cursor: pointer;
    color: var(--red);
    margin: 0 0 0 5px
}


.fc-scrollgrid-sync-inner a {
    text-decoration: none !important;
}

.fc-daygrid-day-events {
    max-height: 40px !important;
}

.fc .fc-daygrid-day-top {
    display: unset;
    text-align: left;
}

.fc-day-other {
    opacity: 50%;
    background: var(--grey-white-3);
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 500;
    /*line-height: 20px;*/
    letter-spacing: 0em;
    text-align: left;
}

.fc-daygrid-day-number {
    padding: 20px 10px !important;
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.fc-col-header-cell-cushion {
    padding: 10px 0 !important;
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.fc-day-today {
    background: var(--light-blue) !important;
}

.fc-event {
    background: var(--primary-25);
    font-family: var(--font-family-regular);
    border-radius: 5px;
    color: var(--black-100);
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin: 5px;
    padding: 5px;
}

.fc-day-today .fc-event {
    background: var(--primary-50);
    font-family: var(--font-family-regular);
    border-radius: 5px;
    color: var(--black-100);
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin: 5px;
    padding: 5px;
}

.fc-today-button
    /*.fc-prev-button,*/
    /*.fc-next-button*/
{
    display: none !important;
}

.fc-header-toolbar {
    padding: 10px 32px;
    margin-bottom: 0px;
}

.fc-toolbar-title {
    font-family: var(--font-family-regular);
    color: var(--black-100);
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.fc-button-primary {
    background-color: transparent !important;
    border-color: transparent !important;
    color: var(--primary-100) !important;
}

.fc-button {
    padding: unset !important
}

.fc-icon:active,
.fc-button:active {
    border: none !important;
}

.fc-button-group {
    display: none;
}

.fc-h-event {
    border: none !important;
    color: var(--black-100) !important
}

.fc-event-main {
    border: none !important;
    color: var(--black-100) !important
}

.fc-scrollgrid {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}

.fc-scrollgrid thead th {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.fc-scrollgrid tr:first-child td:first-child {
    border-top-left-radius: 0;
}

.fc-scrollgrid tr:first-child td:last-child {
    border-top-right-radius: 0;
}

.fc-scrollgrid tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.fc-scrollgrid tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

tr:first-child td {
    border-top-style: solid;
}

tr td:first-child {
    border-left-style: solid;
}

.fc-button, .fc-button-group {
    outline: none !important
}

.fc-button-primary {
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
}

.fc-daygrid-more-link, .fc-more-link {
    color: var(--primary-75);
}

.fc-daygrid-more-link:hover, .fc-more-link:hover {
    color: var(--black-75);
    background: transparent !important;
}

.fc-event {
    border: none !important;
}

.fc .fc-popover {
    z-index: 2 !important;
}
.fc-event, .event-title {
    /*padding: 0 1px;*/
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: "....";
}

.card-body-table thead {
    display: none;
}
.card-body-table tbody tr {
    float: left;
    margin: 0.5em;
    border: 1px solid var(--white);
    border-radius: 10px !important;
    background-color: var(--white);
    box-shadow: 0px 4px 20px 0px #0000000D;
}
.card-body-table tbody tr td {
    display: block;
    border: 0;
    border-radius: 10px !important;
    background-color: transparent;
    padding: 16px 16px 0 16px
}
.card-body-table tbody tr:nth-child(3n+2) {
    margin: 0 16px!important;
}
.card-body-table tbody tr:nth-child(3n+1) {
    margin: 0 0 16px 0!important;
}
.card-body-table tbody tr:nth-child(3n) {
    margin: 0 0 16px 0!important;
}
table.dataTable tbody tr {
    background-color: var(--white);
    height: fit-content !important;
    width: 32.3% !important;
}
.template-card-title {
    font-family: var(--font-family-regular);
    font-size: 16px !important;
    font-weight: 700;
    line-height: 24px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-100);
}
.template-card-text-right {
    text-align: right !important;
}
.template-card-description {
    font-family: var(--font-family-regular);
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-100);
    min-height: 65px;
    max-height: 65px;
}
.card-body-table .card {
    width: 32.3% !important;
}
.card-body-letters .card {
    width: 32.3% !important;
}

.card-body-letters thead {
    display: none;
}
.card-body-letters tbody tr {
    float: left;
    margin: 0.5em;
    border: 1px solid var(--white);
    border-radius: 10px !important;
    background-color: var(--white);
    box-shadow: 0px 4px 20px 0px #0000000D;
    height: 230px;
    max-height: 250px;
}
.card-body-letters tbody tr td {
    display: block;
    border: 0;
    border-radius: 10px !important;
    background-color: transparent;
    padding: 16px 16px 0 16px
}
.card-body-letters tbody tr:nth-child(3n+2) {
    margin: 0 16px!important;
}
.card-body-letters tbody tr:nth-child(3n+1) {
    margin: 0 0 16px 0!important;
}
.card-body-letters tbody tr:nth-child(3n) {
    margin: 0 0 16px 0!important;
}
.card-body-letters .table-container {
    min-height: 65vh !important;
}
